import React, { useEffect, useState } from "react";
import { Socials } from "../assets/data/socials";
import { useLocation } from "react-router-dom";

const SocialsLign = () => {
  const [hid, setHid] = useState(true);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== "/" && location.pathname !== "/links") {
      setHid(false);
    } else setHid(true);
  }, [location]);

  return (
    <>
      {!hid && (
        <div className="fixed bottom-0 left-0 z-50 flex flex-col items-center justify-center gap-4 ml-4 lg:ml-8 ">
          <div className="flex flex-col gap-4 mt-4">
            {Socials.map((social, i) => {
              return (
                <a
                  key={i}
                  className="block mb-2 text-xl hover:scale-110"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={social.url}
                >
                  <social.Icon className="text-gray-800 hover:text-primary dark:text-texlight" />
                </a>
              );
            })}
          </div>

          <div className="flex flex-col items-center justify-center">
            <div className="w-[0.25rem] h-[15vh] md:h-[20vh]: lg:h-[25vh] bg-primary"></div>
          </div>
        </div>
      )}
    </>
  );
};

export default SocialsLign;
