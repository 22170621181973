import React from "react";
import { motion } from "framer-motion";

import Leaf1 from "../img/Leaf.png";
import Leaf2 from "../img/leaf2.png";

const SectionTitle = (props) => {
  return (
    <>
      <div className="flex items-center justify-center w-full mb-5">
        <motion.div
          initial={{ opacity: 0, width: 0 }}
          animate={{ opacity: 1, width: 200 }}
          exit={{ opacity: 0, width: 0 }}
          transition={{ delay: 0.4 }}
          className="flex items-center justify-around w-52"
        >
          <img src={Leaf1} className="object-contain w-6 h-auto" alt="leaf1" />
          <p className="mx-5 text-3xl font-semibold text-transparent uppercase croissant-One_font bg-clip-text bg-gradient-to-r from-primary to-secondary">
            {props.title}
          </p>
          <img src={Leaf2} className="object-contain w-6 h-auto" alt="leaf2" />
        </motion.div>
      </div>
    </>
  );
};

export default SectionTitle;
