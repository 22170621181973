import React, { useState, useEffect, useContext } from "react";
import { AnimatePresence } from "framer-motion";

import { LangueContext } from "../LangueContext";

import SectionTitle from "../SectionTitle";
import Loding from "../Loding";
import ProjectsFilter from "./ProjectsFilter";

import { projectsData } from "../../assets/data/projects";

const ProjectCard = ({ project }) => {
  const { langue } = useContext(LangueContext);
  return (
    <>
      <div className="overflow-hidden bg-transparent border border-gray-300 shadow-xl hover:border-gray-300 dark:border-gray-800 dark:hover:border-gray-800 card rounded-xl backdrop-blur-sm shadow-4xl shadow-white dark:shadow-black hover:scale-105">
        <div className="container flex flex-col justify-between h-full p-4">
          <div className="">
            <img
              loading="lazy"
              className="object-cover w-full max-w-full rounded-lg tokenImage"
              src={project.imgSrc}
              alt={project.name}
            />
            <h2 className="my-3 text-xl text-primary">{project.name}</h2>
            <p className=" text-sm md:text-lg lg:text-xl text-gray-700 dark:text-texlight">
              {langue === "en"
                ? project.desc.slice(0, 320) + "..."
                : project.descFr.slice(0, 320) + "..."}
            </p>
          </div>

          <div>
            <div className="flex flex-wrap items-center justify-center gap-3 my-5 technologies">
              {project.technologies?.map((tech, i) => {
                return (
                  <div
                    className="flex w-fit items-center justify-center"
                    key={i}
                  >
                    <img
                      loading="lazy"
                      style={{
                        width: "40px",
                        height: "40px",
                        borderColor: tech.color,
                      }}
                      src={tech.icon}
                      alt={tech.name}
                      className="p-1 border rounded-full"
                    />
                  </div>
                );
              })}
            </div>

            <hr className="my-3 text-black dark:text-white" />

            <div className="flex items-center justify-center gap-3 my-4 text-xl">
              <a
                className="px-3 py-1 transition bg-transparent border rounded border-primary text-primary hover:scale-105 "
                target="_blanc"
                href={project.gitUrl}
              >
                Github Repositry
              </a>
              <a
                className="px-3 py-1 transition bg-transparent border rounded border-primary text-primary hover:scale-105 "
                target="_blanc"
                href={project.liveDemo}
              >
                Live Demo
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Projects = () => {
  const [projects, setProjects] = useState(projectsData);
  // const [filteredProjects, setFilteredProjects] = useState([]);
  const [searchTech, setSearchTech] = useState("");
  const [loding, setLoding] = useState(true);
  const [lodingProjects, setLodingProjects] = useState(true);

  useEffect(() => {
    setLoding(true);
    if (SectionTitle && projectsData) {
      setTimeout(() => setLoding(false), 400);
    }
  }, []);

  const getSearchTech = (tech) => {
    setSearchTech(tech);
  };

  useEffect(() => {
    setLodingProjects(true);
    if (searchTech === "") {
      setProjects(projectsData);
      setLodingProjects(false);
    } else {
      let filtered = projectsData.filter((project) => {
        return project.technologies.some((tech) =>
          tech.name
            .toLowerCase()
            .trim()
            .includes(searchTech.toLowerCase().trim())
        );
      });
      setProjects(filtered);
      setLodingProjects(false);
    }
  }, [searchTech]);

  return (
    <>
      <section
        id="projects"
        className="z-10 flex flex-col items-center justify-center w-full h-full min-h-screen pl-16 pr-5 py-16 overflow-hidden lg:px-32"
      >
        {/* Title */}
        <SectionTitle title="Projects" />

        {/* Main Projects Content */}
        {loding ? (
          <Loding />
        ) : (
          <>
            <ProjectsFilter getSearchTech={getSearchTech} />
            {lodingProjects ? (
              <>
                <div className="flex mx-auto items-center justify-center bg-transparent w-full h-[30vh]">
                  <svg
                    class="animate-spin h-10 w-10 border-4 border-t-transparent border-green-500  mr-3 bg-transparent rounded-full"
                    viewBox="0 0 24 24"
                  ></svg>
                </div>
              </>
            ) : (
              <div className="grid w-full grid-cols-1 gap-10 mt-4 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 ">
                <AnimatePresence>
                  {projects.length > 0
                    ? projects &&
                      projects.map((project, i) => (
                        <ProjectCard key={project.id} project={project} />
                      ))
                    : searchTech !== "" && (
                        <div
                          class="bg-primary col-span-3 mx-auto w-fit  border-t-4 border-teal-500 rounded-b text-teal-900 px-20   py-3 shadow-md"
                          role="alert"
                        >
                          <div class="flex items-center">
                            <div class="py-1">
                              <svg
                                class="fill-current h-6 w-6 text-texlight mr-4"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                              >
                                <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                              </svg>
                            </div>
                            <div>
                              <p class="font-bold">
                                No projects found using "{searchTech}".
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                </AnimatePresence>
              </div>
            )}
          </>
        )}
      </section>
    </>
  );
};

export default Projects;
