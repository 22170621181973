// Icons
import {
  FaDiagramProject,
  FaRenren,
  FaEnvelope,
  FaHouse,
  FaUser,
} from "react-icons/fa6";

// Navigation
export const Menus = [
  {
    id: `home-${Date.now()}`,
    Icon: FaHouse,
    url: "",
    name: "Home",
  },
  {
    id: `about-${Date.now()}`,
    Icon: FaUser,
    url: "about",
    name: "About",
  },
  {
    id: `projects-${Date.now()}`,
    Icon: FaDiagramProject,
    url: "projects",
    name: "Projects",
  },
  {
    id: `skills-${Date.now()}`,
    Icon: FaRenren,
    url: "skills",
    name: "Skills",
  },
  {
    id: `contact-${Date.now()}`,
    Icon: FaEnvelope,
    url: "contact",
    name: "Contact",
  },
];
