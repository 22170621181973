import React, { useContext, useEffect, useState } from "react";

import { LangueContext } from "../LangueContext";
import SectionTitle from "../SectionTitle";
import Loding from "../Loding";

import { about } from "../../assets/data/about";

const About = () => {
  const { langue } = useContext(LangueContext);
  const [loding, setLoding] = useState(true);

  useEffect(() => {
    setLoding(true);
    if (about) setTimeout(() => setLoding(false), 400);
  }, []);

  return (
    <>
      <section
        id="about"
        className="z-10 flex flex-col items-center justify-center w-full h-full min-h-screen pl-16 pr-5 py-16 overflow-hidden lg:px-32"
      >
        {/* Title */}
        <SectionTitle title="About" />

        {/* Main About Content */}
        {loding ? (
          <Loding />
        ) : (
          <div className="grid items-start w-full grid-cols-1 gap-5 text-white lg:grid-cols-2">
            {/* Col "Left" */}
            <div className="col">
              {/* Row "Education" */}
              <div className="mb-10 row">
                <h1 className="mb-8 text-4xl font-semibold text-center text-primary lg:mt-5 lg:text-left croissant-One_font title">
                  <span className="">{"< "}</span>
                  <span>{langue === "en" ? `Education` : `Éducation`}</span>
                  <span className="">{" />"}</span>
                </h1>
                <div className="pb-5 border-b-2 border-l-2 border-gray-800 rounded-xl dark:border-texlight">
                  {about &&
                    about.education.map((edu) => {
                      return (
                        <>
                          <div
                            key={edu.id}
                            className="relative px-5 mb-2 resume-item lg:px-10 "
                          >
                            <h4 className="my-2 text-xl text-black capitalize dark:text-white lg:my-5 lg:text-2xl croissant-One_font">
                              {langue === "en" ? edu.option : edu.optionFr}
                            </h4>
                            <div className="flex flex-col gap-3 text-gray-800 dark:text-texlight lg:px-10 lg:py-2 lg:text-xl">
                              <span className="px-5 py-1 border border-black rounded border-w dark:border-white w-fit">
                                {langue === "en" ? edu.year : edu.yearFr}
                              </span>
                              <h3 className="py-1 capitalize">
                                <div>
                                  {langue === "en"
                                    ? edu.institution
                                    : edu.institutionFr}
                                </div>
                                <div>A {edu.city}</div>
                              </h3>
                              <p className="">
                                {langue === "en" ? edu.desc : edu.descFr}
                              </p>
                              <ul className="px-5 capitalize list-disc">
                                {langue === "en"
                                  ? edu.values &&
                                    edu.values.map((value, i) => (
                                      <li className="" key={i}>
                                        {value}
                                      </li>
                                    ))
                                  : edu.valuesFr &&
                                    edu.valuesFr.map((value, i) => (
                                      <li className="" key={i}>
                                        {value}
                                      </li>
                                    ))}
                              </ul>
                            </div>
                          </div>
                        </>
                      );
                    })}
                </div>
              </div>
            </div>

            {/*  Col "Right" */}
            <div className="col">
              {/* RoW "courses" */}
              <div className="mb-10 row ">
                <h1 className="mb-8 text-4xl font-semibold text-center text-primary lg:mt-5 lg:text-left croissant-One_font title">
                  <span className="">{"< "}</span>
                  <span>{langue === "en" ? `Courses` : `Cours`}</span>
                  <span className="">{" />"}</span>
                </h1>
                <div className="pb-5 border-b-2 border-l-2 border-gray-800 dark:border-texlight rounded-xl">
                  {about &&
                    about.courses.map((cours) => {
                      return (
                        <>
                          <div
                            key={cours.id}
                            className="relative px-5 mb-2 resume-item lg:px-10 "
                          >
                            <h4 className="my-2 text-xl text-black capitalize dark:text-white lg:my-5 lg:text-2xl croissant-One_font">
                              {langue === "en" ? cours.option : cours.optionFr}
                            </h4>
                            <div className="flex flex-col gap-3 text-gray-800 dark:text-texlight lg:px-10 lg:py-2 lg:text-xl">
                              <span className="px-5 py-1 border border-black rounded dark:border-white border-w w-fit">
                                {langue === "en" ? cours.year : cours.yearFr}
                              </span>
                              <h3 className="py-1 capitalize">
                                <div>
                                  {langue === "en"
                                    ? cours.institution
                                    : cours.institutionFr}
                                </div>
                                <div>A {cours.city}</div>
                              </h3>
                              <p className="">
                                {langue === "en" ? cours.desc : cours.descFr}
                              </p>
                              <ul className="px-5 capitalize list-disc">
                                {langue === "en"
                                  ? cours.values &&
                                    cours.values.map((value, i) => (
                                      <li className="" key={i}>
                                        {value}
                                      </li>
                                    ))
                                  : cours.valuesFr &&
                                    cours.valuesFr.map((value, i) => (
                                      <li className="" key={i}>
                                        {value}
                                      </li>
                                    ))}
                              </ul>
                            </div>
                          </div>
                        </>
                      );
                    })}
                </div>
              </div>

              {/* RoW "Interests" */}
              <div className="mb-10 row">
                <h1 className="mb-8 text-4xl font-semibold text-center text-primary lg:mt-5 lg:text-left croissant-One_font title">
                  <span className="">{"< "}</span>
                  <span>
                    {langue === "en" ? `Athers Interst` : `Autres Intérêts`}
                  </span>
                  <span className="">{" />"}</span>
                </h1>
                <div className="pb-5 border-b-2 border-l-2 border-gray-800 dark:border-texlight rounded-xl">
                  {about &&
                    about.interests.map((interst) => {
                      return (
                        <>
                          <div
                            key={interst.id}
                            className="relative px-5 mb-2 resume-item lg:px-10 "
                          >
                            <h4 className="my-2 text-xl text-black capitalize lg:my-5 lg:text-2xl croissant-One_font dark:text-white">
                              {langue === "en"
                                ? interst.option
                                : interst.optionFr}
                            </h4>
                            <div className="flex flex-col gap-3 text-gray-800 dark:text-texlight lg:px-10 lg:py-2 lg:text-xl">
                              <span className="px-5 py-1 border border-black rounded dark:border-white border-w w-fit">
                                {langue === "en"
                                  ? interst.year
                                  : interst.yearFr}
                              </span>
                              <h3 className="py-1 capitalize">
                                <div>
                                  {langue === "en"
                                    ? interst.institution
                                    : interst.institutionFr}
                                </div>
                                <div>A {interst.city}</div>
                              </h3>
                              <p className="">
                                {langue === "en"
                                  ? interst.desc
                                  : interst.descFr}
                              </p>
                              <ul className="px-5 capitalize list-disc">
                                {langue === "en"
                                  ? interst.values &&
                                    interst.values.map((value, i) => (
                                      <li className="" key={i}>
                                        {value}
                                      </li>
                                    ))
                                  : interst.valuesFr &&
                                    interst.valuesFr.map((value, i) => (
                                      <li className="" key={i}>
                                        {value}
                                      </li>
                                    ))}
                              </ul>
                            </div>
                          </div>
                        </>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default About;
