import React, { useEffect, useState, useRef, useContext } from "react";
import { AnimatePresence } from "framer-motion";
import emailjs from "@emailjs/browser";

import SectionTitle from "../SectionTitle";
import Alert from "./Alert.jsx";
import Loding from "../Loding.jsx";
import { LangueContext } from "../LangueContext.js";

const Contact = () => {
  const { langue } = useContext(LangueContext);
  const form = useRef();
  const [loding, setLoding] = useState(true);
  const [loadingSend, setLodingSend] = useState(false);

  const [contactData, setcontactData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });
  const [alert, setalert] = useState({
    isAlert: false,
    message: "",
    status: null,
  });

  useEffect(() => {
    setLoding(true);
    if (SectionTitle && Alert) {
      setTimeout(() => setLoding(false), 400);
    }
  }, []);

  const handleTextChange = (e) => {
    const { name, value } = e.target;
    setcontactData({
      ...contactData,
      [name]: value,
    });
  };

  const isValidDateValue = () => {
    const validateEmail = (email) => {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    if (
      contactData.firstName === "" ||
      contactData.lastName === "" ||
      contactData.email === "" ||
      contactData.message === ""
    ) {
      setalert({
        isAlert: true,
        message:
          langue === "en"
            ? `Required fields cannot be empty!`
            : `Les champs obligatoires ne peuvent pas être vides`,
        status: "warning",
      });
    } else if (!validateEmail(contactData.email)) {
      setalert({
        isAlert: true,
        message:
          langue === "en" ? `Invalid Email Adress!` : `Adresse e-mail invalide`,
        status: "warning",
      });
    } else if (contactData.message.length < 10) {
      setalert({
        isAlert: true,
        message:
          langue === "en"
            ? `The message must be at least 10 characters long!`
            : `Le message doit contenir au moins 10 caractères !`,
        status: "warning",
      });
    } else return true;
    setTimeout(() => {
      setalert({
        isAlert: false,
        message: "",
        status: null,
      });
    }, 4000);
    return false;
  };

  const sendData = async (e) => {
    e.preventDefault();
    setLodingSend(true);

    const senToEmail = async () => {
      await emailjs
        .sendForm("service_xjlt07o", "template_lzqga63", form.current, {
          publicKey: "1E_AurmI5Dxna2adm",
        })
        .then(
          () => {
            setalert({
              isAlert: true,
              message:
                langue === "en"
                  ? `Thans for contacting me !!`
                  : `Merci de m'avoir contacté !!`,
              status: "success",
            });
            setcontactData({
              firstName: "",
              lastName: "",
              email: "",
              message: "",
            });
            setTimeout(() => {
              setalert({
                isAlert: false,
                message: "",
                status: null,
              });
            }, 4000);
          },
          (error) => {
            console.log("FAILED...", error.text);
            setalert({
              isAlert: true,
              message: error.text,
              status: "warning",
            });
            setTimeout(() => {
              setalert({
                isAlert: false,
                message: "",
                status: null,
              });
            }, 4000);
          }
        );
    };

    if (isValidDateValue()) {
      senToEmail();
    }
    setTimeout(() => {
      setLodingSend(false);
    }, 1000);
  };

  return (
    <>
      <section
        id="contact"
        className="z-10 flex flex-col items-center justify-center w-full h-full min-h-screen  pl-16 pr-5 py-16 overflow-hidden lg:px-32"
      >
        {/* Toast Alert Notification */}
        <AnimatePresence>
          {alert.isAlert && (
            <Alert status={alert.status} message={alert.message} />
          )}
        </AnimatePresence>

        {/* Title */}
        <SectionTitle title="Contact" />

        {/* Main Contact Content */}
        {loding ? (
          <Loding />
        ) : (
          <div className="flex flex-col items-center justify-start w-full gap-4 mt-5 croissant-One_font">
            <form
              ref={form}
              onSubmit={sendData}
              className="w-full lg:w-[600px] p-2 flex flex-col items-center justify-start gap-4"
            >
              <div className="grid w-full grid-cols-1 gap-4 lg:grid-cols-2">
                <input
                  type="text"
                  name="firstName"
                  value={contactData.firstName}
                  onChange={handleTextChange}
                  placeholder={langue === "en" ? `First name...` : `Prenom...`}
                  className="w-full px-4 py-3 text-black bg-transparent border border-black rounded-md outline-none dark:focus:border-primary placeholder:text-gray-600 focus:border-primary dark:text-texlight dark:border-texlight focus:scale-105 focus:transition-transform"
                />
                <input
                  type="text"
                  name="lastName"
                  value={contactData.lastName}
                  onChange={handleTextChange}
                  placeholder={langue === "en" ? `Last name...` : `Nom...`}
                  className="w-full px-4 py-3 text-black bg-transparent border border-black rounded-md outline-none dark:focus:border-primary placeholder:text-gray-600 focus:border-primary dark:text-texlight dark:border-texlight focus:scale-105 focus:transition-transform"
                />
              </div>

              <input
                type="email"
                name="email"
                value={contactData.email}
                onChange={handleTextChange}
                placeholder={
                  langue === "en" ? `Email address...` : `Adresse e-mail...`
                }
                className="w-full px-4 py-3 text-black bg-transparent border border-black rounded-md outline-none placeholder:text-gray-600 focus:border-primary dark:focus:border-primary dark:text-texlight dark:border-texlight focus:transition-transform focus:scale-105"
              />
              <textarea
                name="message"
                value={contactData.message}
                onChange={handleTextChange}
                id=""
                cols="0"
                rows="10"
                placeholder={langue === "en" ? `Message hear...` : `Message...`}
                className="w-full px-4 py-3 text-black bg-transparent border border-black rounded-md outline-none dark:focus:border-primary placeholder:text-gray-600 focus:border-primary dark:text-texlight dark:border-texlight focus:transition-transform focus:scale-105"
              ></textarea>

              <div className="flex items-center justify-center w-full lg:justify-end">
                {loadingSend ? (
                  <button
                    disabled="true"
                    className="w-full px-12 py-3 font-semibold rounded-md lg:w-auto bg-gradient-to-br from-primary hover:from-transparent hover:to-transparent to-secondary hover:bg-gradient-to-br hover:border-primary dark:hover:from-black dark:hover:to-black focus:scale-105 hover:text-primary hover:border focus:transition-transform"
                  >
                    <div role="status">
                      <svg
                        aria-hidden="true"
                        class="w-7 h-7 text-gray-200 animate-spin dark:text-gray-600 fill-green-400"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <span class="sr-only">Loading...</span>
                    </div>
                  </button>
                ) : (
                  <input
                    type="submit"
                    value={langue === "en" ? `Send` : `Envoyer`}
                    className="w-full cursor-pointer px-12 py-3 font-semibold rounded-md lg:w-auto bg-gradient-to-br from-primary hover:from-transparent hover:to-transparent to-secondary hover:bg-gradient-to-br hover:border-primary dark:hover:from-black dark:hover:to-black focus:scale-105 hover:text-primary hover:border focus:transition-transform"
                  />
                )}
              </div>
            </form>
          </div>
        )}
      </section>
    </>
  );
};

export default Contact;
