import { useEffect } from "react";

import { useNavigate } from "react-router-dom";

const Cv = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.location.href = "/cv.pdf";
    navigate("/");
  }, [navigate]);

  return null;
};

export default Cv;
