// Icons
import {
  FaEnvelope,
  FaGithub,
  FaLinkedinIn,
  FaSquareInstagram,
} from "react-icons/fa6";

// Socials Contacts
export const Socials = [
  {
    id: `linkedin-${Date.now()}`,
    Icon: FaLinkedinIn,
    url: "https://www.linkedin.com/in/mzilinassim/",
    color: "#0072b1",
  },
  {
    id: `github-${Date.now()}`,
    Icon: FaGithub,
    url: "https://github.com/mziliNassim",
    color: "#fff",
  },
  {
    id: `Instagram-${Date.now()}`,
    Icon: FaSquareInstagram,
    url: "https://www.instagram.com/nassim__dev/",
    color: "#1877F2",
  },
  {
    id: `Instagram-${Date.now()}`,
    Icon: FaEnvelope,
    url: "mailto:mzilinassim@gmail.com",
    color: "#1877F2",
  },
];
