import React, { useContext } from "react";
import { LangueContext } from "../LangueContext";

const ProjectsFilter = ({ getSearchTech }) => {
  const { langue } = useContext(LangueContext);

  const handelSearch = (e) => {
    getSearchTech(e.target.value);
  };

  return (
    <>
      <nav className="flex flex-wrap items-center justify-center w-full px-6 py-3 my-5 bg-transparent border border-gray-300 shadow-sm dark:shadow-white dark:border-gray-700 rounded-xl backdrop-blur-sm">
        <div className="flex items-center flex-shrink-0 mb-3 mr-6 text-white md:mb-0 ">
          <svg
            className="w-6 h-6 mr-2 text-gray-500 fill-current dark:text-white"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path d="M5.05 3C3.291 3 2.352 5.024 3.51 6.317l5.422 6.059v4.874c0 .472.227.917.613 1.2l3.069 2.25c1.01.742 2.454.036 2.454-1.2v-7.124l5.422-6.059C21.647 5.024 20.708 3 18.95 3H5.05Z" />
          </svg>

          <span className="text-xl font-semibold tracking-tight text-gray-500 capitalize dark:text-white">
            {langue === "en"
              ? `Filter by technologies`
              : `Filtrer par technologies.`}
          </span>
        </div>
        <div className="justify-end flex-grow block w-full lg:flex lg:items-center md:w-auto">
          {/* Search  */}
          <div className="w-full text-right bg-red-0">
            <input
              className="w-full px-3 py-2 leading-tight bg-transparent border border-gray-300 rounded shadow appearance-none lg:w-1/2 dark:border-gray-700 text-L focus:outline-none focus:shadow-outline text-texlight"
              id="username"
              onChange={handelSearch}
              type="text"
              placeholder="Technologies..."
            />
          </div>
        </div>
      </nav>
    </>
  );
};

export default ProjectsFilter;
