import React, { useContext, useEffect, useState } from "react";

import SectionTitle from "../SectionTitle";
import SkillCard from "./SkillCard";
import Loding from "../Loding";

// import { skillsDataWeb, environment, styles } from "../../assets/data";
import { skillsDataWeb, environment, styles } from "../../assets/data/skills";

import { LangueContext } from "../LangueContext";

const Skills = () => {
  const { langue } = useContext(LangueContext);
  const [loding, setLoding] = useState(true);

  useEffect(() => {
    setLoding(true);
    if (SkillCard) {
      setTimeout(() => setLoding(false), 400);
    }
  }, []);

  return (
    <>
      <section
        id="skills"
        className="z-10 flex flex-col items-center justify-center w-full h-full min-h-screen pl-16 pr-5 py-16 overflow-hidden lg:px-32"
      >
        {/* Title */}
        <SectionTitle title="Skills" />

        {/* Main Skills Content */}
        {loding ? (
          <Loding />
        ) : (
          <>
            <div className="flex-col items-center w-full justify-center d-flex">
              {/* Top Side */}
              <div className="grid w-full grid-cols-1 gap-5 lg:grid-cols-2">
                {/* "skillsDataWeb" */}
                <div className="">
                  <div className="w-full px-8 py-5 mb-10 rounded shadow lg:w-auto dark:shadow-white shadow-black">
                    <h1 className="mt-5 mb-8 text-base font-semibold text-center md:text-xl lg:text-2xl croissant-One_font title">
                      <span className="text-orange-400">{"< "}</span>
                      <span className="text-black dark:text-white">
                        {langue === "en"
                          ? `WebDevelopment`
                          : `Développement Web`}
                      </span>
                      <span className="text-orange-400">{" />"}</span>
                    </h1>
                    <div className="grid items-center justify-center grid-cols-1 gap-5 sm:grid-cols-3 md:grid-cols-3 xl:grid-cols-4">
                      {skillsDataWeb[0].skills.map((skill, i) => {
                        return (
                          <div
                            key={i}
                            className="flex xl:col-span-2 items-center justify-center p-5 border-4 rounded-lg col-6"
                            style={{ borderColor: skill.color }}
                          >
                            <img
                              src={skill.icon}
                              alt={skill.title}
                              style={{ width: "100px", height: "150px" }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>

                <div className="">
                  {/* "Environment" */}
                  <div className="w-full px-8 py-5 mb-10 rounded shadow shadow-black dark:shadow-white lg:w-auto">
                    <h1 className="mt-5 mb-8 text-base font-semibold text-center md:text-xl lg:text-2xl croissant-One_font title">
                      <span className="text-orange-400">{"< "}</span>
                      <span className="text-black dark:text-white">
                        {langue === "en" ? `Environment` : `Environnement`}
                      </span>
                      <span className="text-orange-400">{" />"}</span>
                    </h1>
                    <div className="grid w-full items-center justify-center grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-4">
                      {environment[0].skills.map((skill, i) => {
                        return (
                          <div
                            key={i}
                            className="flex xl:col-span-2 items-center justify-center p-5 border-4 rounded-lg col-6"
                            style={{ borderColor: skill.color }}
                          >
                            <img
                              src={skill.icon}
                              alt={skill.title}
                              style={{ width: "100px", height: "150px" }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  {/* "Styles" */}
                  <div className="w-full px-8 py-5 mb-10 rounded shadow shadow-black dark:shadow-white lg:w-auto">
                    <h1 className="mt-5 mb-8 text-base font-semibold text-center md:text-xl lg:text-2xl croissant-One_font title">
                      <span className="text-orange-400">{"< "}</span>
                      <span className="text-black dark:text-white">Styles</span>
                      <span className="text-orange-400">{" />"}</span>
                    </h1>
                    <div className="grid w-full items-center justify-center grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-4">
                      {styles[0].skills.map((skill, i) => {
                        return (
                          <div
                            key={i}
                            className="flex items-center xl:col-span-2 justify-center p-5 border-4 rounded-lg col-6"
                            style={{ borderColor: skill.color }}
                          >
                            <img
                              src={skill.icon}
                              alt={skill.title}
                              style={{ width: "100px", height: "150px" }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </section>
    </>
  );
};

export default Skills;
