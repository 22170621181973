import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { NavLink } from "react-router-dom";

const MenuItem = ({ menu }) => {
  const [isHovered, setisHovered] = useState(false);

  return (
    <>
      <NavLink
        to={`/${menu.url}`}
        className="relative flex items-center justify-center w-auto p-1 rounded-full cursor-pointer W lg:h-12 lg:w-12 navLink group hover:bg-gradient-to-br hover:from-primary hover:to-secondary"
        onMouseEnter={() => setisHovered(true)}
        onMouseLeave={() => setisHovered(false)}
      >
        <menu.Icon className="text-xl text-black dark:text-texlight group-hover:text-bgPrimary" />

        <AnimatePresence>
          {isHovered && (
            <motion.div
              initial={{ opacity: 0, x: -25 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -25 }}
              className="croissant-One_font hidden bg-black after:bg-black lg:block absolute dark:bg-white dark:after:bg-white rounded-md px-6 py-2 -left-[140px] after:absolute after:-right-1 after:top-3 after:w-3 after:h-3 after:rotate-45 shadow shadow-black dark:shadow-texlight"
            >
              <p className="text-white dark:text-bgPrimary">{menu?.name}</p>
            </motion.div>
          )}
        </AnimatePresence>
      </NavLink>
    </>
  );
};

export default MenuItem;
