import React, { useContext, useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Link } from "react-router-dom";

import { LangueContext } from "../LangueContext";

import HeroTypeWritter from "./HeroTypeWritter";
import HomeSocialLinks from "./HomeSocialLinks";
import Loding from "../Loding";

// import nassimJpg from "../../img/homeAvatar.jpg";
import nassimJpg from "../../img/linkedin_Pic.jpg";
import { Socials } from "../../assets/data/socials";

const Home = () => {
  const { langue } = useContext(LangueContext);
  const [loding, setLoding] = useState(true);

  useEffect(() => {
    setLoding(true);
    if (nassimJpg && Socials) setTimeout(() => setLoding(false), 400);
  }, []);

  return (
    <>
      <section
        id="home"
        className="z-10 flex flex-col items-center justify-center w-full h-full min-h-screen py-16 pl-16 pr-5 overflow-hidden lg:px-32"
      >
        {loding ? (
          <Loding />
        ) : (
          <div className="grid w-full grid-cols-1 gap-10 lg:gap-4 lg:grid-cols-2">
            {/* Info Content */}
            <div className="flex flex-col items-start justify-center w-full h-full gap-4 lg:items-start">
              <h2 className="text-3xl lg:mx-0 lg:text-4xl croissant-One_font lg:text-left">
                <span className="block mt-4 text-4xl tracking-wider text-black lg:text-6xl dark:text-white">
                  MZILI Nassim
                </span>
              </h2>

              {/* typing profitions */}
              <h2 className="mt-4 text-xl lg:text-4xl croissant-One_font ">
                <span>
                  <HeroTypeWritter
                    words={["Full-Stack Web Developer..."]}
                    speed={100}
                  />
                </span>
              </h2>

              <p className="mt-4 text-2xl text-black lg:text-left dark:text-texlight">
                {langue === "en"
                  ? `Aspiring Full-Stack Web Developer and dedicated student with a
                        passion for creating dynamic, user-friendly web applications.
                        Actively learning front-end and back-end technologies to build
                        innovative solutions for modern web challenges. Committed to
                        growing skills and contributing to impactful digital
                        experiences.`
                  : `Développeur Web Full-Stack en devenir et étudiant motivé,
                      passionné par la création d’applications web simples et efficaces.
                      J’apprends activement les technologies front-end et back-end pour
                      concevoir des solutions modernes et innovantes. Je suis engagé à
                      améliorer mes compétences et à participer à des projets numériques
                      importants.`}
              </p>

              {/* Social links */}
              <div className="flex items-center justify-center w-full gap-8 mt-4 md:w-fit">
                <AnimatePresence>
                  {Socials &&
                    Socials.map((link, i) => (
                      <HomeSocialLinks key={i} data={link} index={i} />
                    ))}
                </AnimatePresence>
              </div>

              {/* Buttons */}
              <div className="flex items-center justify-center w-full gap-5 md:w-fit d">
                <Link
                  to="/contact"
                  className="px-8 py-3 mt-4 border border-black shadow-inner shadow-white croissant-One_font rounded-xl active:95 group hover:border-primary dark:border-texlight dark:shadow-texlight"
                >
                  <span className="text-black group-hover:text-transparent group-hover:from-primary group-hover:to-secondary group-hover:bg-clip-text group-hover:bg-gradient-to-r dark:text-texlight">
                    {langue === "en" ? `Hear ME` : `Contacter`}
                  </span>
                </Link>
              </div>
            </div>

            {/* Img */}
            <div className="flex items-start justify-center w-full h-full">
              <motion.img
                loading="lazy"
                initial={{ y: 0 }}
                animate={{ y: [-10, 10, -10] }}
                src={nassimJpg}
                transition={{
                  repeat: Infinity,
                  duration: 4,
                  ease: "linear",
                }}
                className="object-contain w-auto h-auto  rounded-full"
                style={{ width: "500px" }}
                alt="Home"
              />
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default Home;
