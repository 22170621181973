import React from "react";

import MenuItem from "./MenuItem";

import { Menus } from "../../assets/data/Navigation";

const Header = () => {
  return (
    <>
      <div className="fixed bottom-0 right-0 z-50 flex items-end justify-center w-full h-auto pb-4 lg:top-0 lg:h-screen lg:w-32 lg:items-center lg:pb-0">
        <div className="flex flex-row items-center justify-between w-full px-4 py-3 duration-200 border border-black rounded-full lg:py-12 lg:flex-col lg:justify-center lg:gap-12 backdrop-blur-md lg:w-auto dark:border-texlight">
          {Menus &&
            Menus.map((menu, i) => <MenuItem key={i} menu={menu} id={i} />)}
        </div>
      </div>
    </>
  );
};

export default Header;
