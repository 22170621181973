import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Config from "./Config";
import ParticlesContainer from "./ParticlesContainer";
import SocialsLign from "./SocialsLign.jsx";

import Navigation from "./navigation/Navigation";
import Home from "./home/Home";
import About from "./about/About";
import Skills from "./skills/Skills";
import Projects from "./projects/Projects";
import Contact from "./contact/Contact";
import NotFound from "./notFound/NotFound";

import Links from "./links/AllLinks.jsx";
import Cv from "./Cv";

const App = () => {
  return (
    <>
      <ParticlesContainer />
      <div className="flex items-center justify-center w-screen h-full overflow-auto text-black bg-white dark:bg-black dark:text-white">
        <BrowserRouter>
          <Config />
          <SocialsLign />
          <Navigation />
          <Routes>
            <Route path="/" element=<Home /> />
            <Route path="/home" element=<Home /> />
            <Route path="/about" element=<About /> />
            <Route path="/skills" element=<Skills /> />
            <Route path="/projects" element=<Projects /> />
            <Route path="/contact" element=<Contact /> />

            <Route path="/links" element=<Links /> />
            <Route path="/cv" element=<Cv /> />

            <Route path="*" element=<NotFound /> />
          </Routes>
        </BrowserRouter>
      </div>
    </>
  );
};

export default App;
