// About
export const about = {
  education: [
    {
      id: 1,
      option: "Specialized Technician in Full-Stack Web Development",
      optionFr: "Technicien Spécialisé en web full-stack development",
      institution: "Higher Institute of Applied Techniques (ISTA)",
      institutionFr: "Institut Supérieur des Techniques Appliquées (ISTA)",
      city: "Tinghir",
      year: "2023 - ...",
      yearFr: "2023 - ...",
      desc: ``,
      descFr: ``,
      values: [
        "Full-Stack developemnt",
        "SQL and NoSQL DBs",
        "DevOps and Microservices",
        "Soft-skills",
        "Langues: English && Frensh",
      ],
      valuesFr: [
        "Full-Stack developemnt",
        "SQL et NoSQL DBs",
        "DevOps et Microservices",
        "Soft-skills",
        "Langues : Anglais et Français",
      ],
    },
    {
      id: 2,
      option: "Mathematical and Computer Sciences (SMI)",
      optionFr: "Sciences Mathématique et informatique (SMI)",
      institution: 'faculty of sciences "SEMLALIA"',
      institutionFr: 'Faculté des Sciences Semlalia"',
      city: "Marrakech",
      year: "2019 - 2022",
      yearFr: "2019 - 2022",
      desc: ``,
      descFr: ``,
      values: [
        "computer hardware and software",
        "algorithms and Problems solving",
        "Mathimatiques",
      ],
      valuesFr: [
        "Matériel et logiciel informatique",
        "Algorithmes et résolution de problèmes",
        "Mathématiques",
      ],
    },
    {
      id: 3,
      option: "Baccalaureate in Mathematical Sciences (B)",
      optionFr: "Baccalauréat Science Mathématique (B)",
      institution: 'Technical High School "Iben Al Haitam"',
      institutionFr: 'Lycée Technique "Iben Al Haitam"',
      city: "Ouarzazate",
      year: "2019",
      yearFr: "2019",
      desc: ``,
      descFr: ``,
      values: [],
      valuesFr: [],
    },
  ],
  courses: [
    {
      id: 1,
      option: "Mastering Microsoft Office Tools",
      optionFr: "Formation De Maitrise Les Outils Bureautiques De Microsoft",
      institution: "Center De Formation De Compétence Et D’accompagnement",
      institutionFr: "Center De Formation De Compétence Et D’accompagnement",
      city: "Marrakech",
      year: "3 Month - 2022",
      yearFr: "3 Mois - 2022",
      desc: ``,
      descFr: ``,
      values: ["computer hardware", `Microsoft office Tols`],
      valuesFr: ["Matériel informatique", "Outils Microsoft Office"],
    },
  ],
  interests: [
    {
      id: 1,
      option: "Automobile Electronic Diagnostics",
      optionFr: "formation en diagnostic électronique des voitures",
      institution: 'Training Center "Atlantic"',
      institutionFr: 'Centre "Atlantique" Formation',
      year: "6 Month - 2022",
      yearFr: "6 Mois - 2022",
      city: "Marrakech",
      desc: ``,
      descFr: ``,
      values: [
        "Effectively diagnose electronic vehicle issues",
        "Identify malfunctions using advanced diagnostic tools",
        "Proficient with a wide range of car brands and models",
      ],
      valuesFr: [
        "Diagnostiquer efficacement les problèmes des véhicules électriques",
        "Identifier les dysfonctionnements en utilisant des outils de diagnostic avancés",
        "Compétent avec une large gamme de marques et de modèles de voitures",
      ],
    },
  ],
};
