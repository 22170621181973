import React, { createContext, useState } from "react";

// Create the context
export const LangueContext = createContext();

// Create a provider component
export const LangueProvider = ({ children }) => {
  const [langue, setLangue] = useState("fr");

  return (
    <LangueContext.Provider value={{ langue, setLangue }}>
      {children}
    </LangueContext.Provider>
  );
};
