// projects bg "imgs"
// import menuFood from "../../img/Projects/menufood.png";
import postman from "../../img/Projects/postman_project.png";
import wiki from "../../img/Projects/wiki.png";
import tradingCalculator from "../../img/Projects/tradingcalculator.png";
import cinema from "../../img/Projects/cinema.png";

// projects technologies "Icons"
import reactIcon from "../../img/skills/reacticon.svg";
import axiosicon from "../../img/skills/axiosicon.svg";
import bootstrapicon from "../../img/skills/bootstrap.svg";
import tailwindicon from "../../img/skills/tailwindicon.svg";
import redux from "../../img/skills/reduxicon.svg";
import mongodb from "../../img/skills/mongodb.svg";
import express from "../../img/skills/expressicon.svg";

// Projects Data
export const projectsData = [
  {
    id: `cinema-${Date.now()}`,
    name: "CinemA",
    desc: `MERN (MongoDB, Express, React, Node.js) stack application
      designed for movie enthusiasts. The front end consumes data from The
      Movie Database (TMDb) API, enabling users to explore various movie
      categories. The back end handles user authentication, registration,
      and login using MongoDB.`,
    descFr: `Une application basée sur la pile MERN (MongoDB, Express,
      React, Node.js) conçue pour les passionnés de cinéma. L'interface
      front-end consomme des données provenant de l'API The Movie Database
      (TMDb), permettant aux utilisateurs d'explorer diverses catégories de
      films. Le back-end gère l'authentification des utilisateurs,
      l'enregistrement et la connexion à l'aide de MongoDB.`,
    imgSrc: cinema,
    gitUrl: "https://github.com/mziliNassim/cinema-mern/",
    liveDemo: "https://cinema.nassim.online/",
    technologies: [
      { name: "React JS", icon: reactIcon, color: "#00d8ff" },
      { name: "Redux/toolkit", icon: redux, color: "#704fa1" },
      { name: "Axios", icon: axiosicon, color: "#5a29e4" },
      { name: "express", icon: express, color: "#9e9e9e" },
      { name: "mongodb", icon: mongodb, color: "#13aa52" },
      { name: "tailwindicon", icon: tailwindicon, color: "#44a8b3" },
    ],
  },
  {
    id: `postman-${Date.now()}`,
    name: "Postman Clone",
    desc: `A robust and user-friendly web application designed to test
      HTTP requests such as "GET," "POST," "PUT," and more. Built with
      React JS, styled using Bootstrap, and powered by Redux Toolkit for
      state management, the application offers a seamless experience for
      developers to interact with APIs. With Axios handling API calls,
      this tool simplifies the process of sending requests and analyzing
      responses, making it an essential resource for debugging and API
      testing.`,
    descFr: `Une application web puissante et intuitive conçue pour
      tester les requêtes HTTP telles que "GET", "POST", "PUT", et bien
      d'autres. Développée avec React JS, stylisée avec Bootstrap, et
      utilisant Redux Toolkit pour la gestion de l'état, cette application
      offre une expérience fluide aux développeurs pour interagir avec des
      API. Grâce à Axios pour la gestion des appels API, cet outil simplifie
      l'envoi de requêtes et l'analyse des réponses, en faisant un outil
      indispensable pour le débogage et les tests d'API.`,
    imgSrc: postman,
    gitUrl: "https://github.com/mziliNassim/postman-clone-react",
    liveDemo: "https://postmanclone.nassim.online/",
    technologies: [
      { name: "React JS", icon: reactIcon, color: "#00d8ff" },
      { name: "Redux/toolkit", icon: redux, color: "#704fa1" },
      { name: "Axios", icon: axiosicon, color: "#5a29e4" },
      { name: "Bootstrap", icon: bootstrapicon, color: "#704fa1" },
    ],
  },
  {
    id: `wiki-${Date.now()}`,
    name: "Wiki Search",
    desc: `simple and intuitive search WebApplication allows users to search
      for articles and retrieve relevant information quickly and efficiently.
      Built with React JS, styled using Bootstrap, and powered by the
      Wikipedia API, the application provides an easy way to explore a vast
      array of knowledge.`,
    descFr: `WebApplication de recherche simple et intuitif qui permet
      aux utilisateurs de rechercher des articles et de récupérer des informations
      pertinentes rapidement et efficacement. Conçue avec React JS, stylisée
      à l'aide de Bootstrap et alimentée par l'API Wikipedia, l'application
      offre un moyen simple d'explorer un vaste éventail de connaissances.`,
    imgSrc: wiki,
    gitUrl: "https://github.com/mziliNassim/wiki-react",
    liveDemo: "https://wikipedia.nassim.online/",
    technologies: [
      { name: "React JS", icon: reactIcon, color: "#00d8ff" },
      { name: "Axios", icon: axiosicon, color: "#5a29e4" },
      { name: "Bootstrap", icon: bootstrapicon, color: "#704fa1" },
    ],
  },
  {
    id: `Calculator-${Date.now()}`,
    name: "Trading Calculator",
    desc: `Simple Trading Calculator Web Application is a user-friendly tool
      designed to help traders efficiently calculate essential trading
      metrics, ensuring accurate trade planning and effective risk
      management. Built using React.js for dynamic functionality and
      Bootstrap for responsive design, this application is both practical
      and easy to use.`,
    descFr: `Web Application Simple Trading Calculator est un outil convivial
      conçu pour aider les traders à calculer efficacement les indicateurs de
      trading essentiels, garantissant une planification précise des
      transactions et une gestion efficace des risques. Conçue à l'aide de
      React JS pour une fonctionnalité dynamique et de Bootstrap pour une
      conception réactive, cette application est à la fois pratique et
      facile à utiliser.`,
    imgSrc: tradingCalculator,
    gitUrl: "https://github.com/mziliNassim/trading-calculator-react",
    liveDemo: "https://calculatortrading.nassim.online/",
    technologies: [
      { name: "React JS", icon: reactIcon, color: "#00d8ff" },
      { name: "Bootstrap", icon: bootstrapicon, color: "#704fa1" },
    ],
  },
  // {
  //   id: `food-${Date.now()}`,
  //   name: "ResToresT",
  //   desc: `Dynamic platform showcasing a variety of meals from around the
  //     world. Built using React JS, styled with Bootstrap for a responsive
  //     and user-friendly design, and powered by an external API for
  //     fetching meal data, the application provides users with an engaging
  //     and seamless browsing experience.`,
  //   descFr: `Plateforme dynamique présentant une variété de plats du
  //     monde entier. Conçue à l'aide de React JS, stylisée avec Bootstrap
  //     pour une conception réactive et conviviale, et alimentée par une
  //     API externe pour récupérer les données de repas, l'application
  //     offre aux utilisateurs une expérience de navigation attrayante et
  //     fluide.`,
  //   imgSrc: menuFood,
  //   gitUrl: "https://github.com/mziliNassim/menu-food",
  //   liveDemo: "https://nassimmenufood.nassim.online/",
  //   technologies: [
  //     { name: "React JS", icon: reactIcon, color: "#00d8ff" },
  //     { name: "Axios", icon: axiosicon, color: "#5a29e4" },
  //     { name: "Bootstrap", icon: bootstrapicon, color: "#704fa1" },
  //   ],
  // },
];
