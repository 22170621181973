import React, { useContext } from "react";

import { Link } from "react-router-dom";
import { LangueContext } from "../LangueContext";

const NotFound = () => {
  const { langue } = useContext(LangueContext);
  return (
    <>
      <main className="z-10 flex flex-col items-center justify-center w-full h-full py-16 overflow-hidden sm:px-1 md:px-10 lg:px-32 lg:h-screen">
        <p className="text-4xl font-semibold text-primary">404</p>
        <h1 className="mt-4 text-5xl font-semibold tracking-tight text-black text-balance dark:text-white sm:text-7xl">
          {langue === "en" ? `Page not found` : `Page non trouvée`}
        </h1>
        <p className="mt-6 text-lg font-medium text-gray-800 dark:text-texlight text-pretty sm:text-xl/8">
          {langue === "en"
            ? `Sorry, we couldn’t find the page you’re looking for.`
            : `Désolé, nous n'avons pas pu trouver la page que vous recherchez.`}
        </p>
        <div className="flex items-center justify-center mt-10 gap-x-6">
          <Link
            to={`/`}
            className="rounded-md bg-primary px-3.5 py-2.5 text-sm font-semibold text-black shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            {langue === "en" ? `Go back home` : `Retour à l'accueil`}
          </Link>
          <Link
            to={`/contact`}
            className="text-sm font-semibold text-black dark:text-white"
          >
            {langue === "en" ? `Contact support` : `Contacter le support`}
            <span aria-hidden="true">&rarr;</span>
          </Link>
        </div>
      </main>
    </>
  );
};

export default NotFound;
