import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

const HomeSocialLinks = ({ data, index }) => {
  const [isHoverd, setisHoverd] = useState(false);

  return (
    <>
      <motion.a
        initial={{ opacity: 0, y: 25 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 25 }}
        transition={{ delay: index * 0.1 }}
        href={data.url}
        target="_blank"
        key={index}
        className="w-12 h-12 cursor-pointer rounded-full bg-gradient-to-br from-primary to-secondary relative p-[2px]"
        onMouseEnter={() => setisHoverd(true)}
        onMouseLeave={() => setisHoverd(false)}
      >
        <AnimatePresence>
          {isHoverd && (
            <motion.div className="absolute inset-1 blur-md bg-gradient-to-br from-primary to-secondary -z-10"></motion.div>
          )}
          <div className="flex items-center justify-center w-full h-full bg-gray-200 rounded-full dark:bg-black">
            <data.Icon className="text-black dark:text-texlight" />
          </div>
        </AnimatePresence>
      </motion.a>
    </>
  );
};

export default HomeSocialLinks;
