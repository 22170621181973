import React from "react";
import { Link } from "react-router-dom";

import { links } from "../../assets/data/allLinks";
import avatar from "../../img/linkedin_Pic.jpeg";
import HeroTypeWritter from "../home/HeroTypeWritter";

const AllLinks = () => {
  return (
    <>
      <section id="Links" className="z-10 w-full h-full overflow-hidden">
        <div className="w-full min-h-screen pb-28">
          <div className="relative w-full">
            <div className="h-[20vh] shadow-lg dark:shadow-gray-800 shadow-gray-300 bg-gray-200 dark:bg-black opacity-60"></div>
            <div className="h-[20vh] bg-gray-300 shadow-lg opacity-0 bg-opacity-80 backdrop-blur-sm dark:shadow-gray-800 shadow-gray-300 dark:bg-gray-900"></div>
            <img
              src={avatar}
              alt="avatar"
              className="rounded-full absolute w-[150px] sm:w-[150px] md:w-[170px] lg:w-[250px] xl:w-[250px] top-1/2 -translate-y-1/2 shadow-md -translate-x-1/2 lg:translate-x-0 left-1/2  lg:left-32 xl:left-48"
            />
          </div>

          <div className="grid w-full md:w-10/12 grid-cols-1 mx-auto -mt-20 min-h-[70vh] md:gap-5 lg:grid-cols-2">
            {/* Left */}
            <div className="w-full md:border-l mt-7 lg:mt-20 dark:border-gray-700 border-gray-300 rounded flex flex-col px-5 pt-5 pb-0 lg:p-5">
              <h2 className="lg:mx-0  croissant-One_font lg:text-left text-center">
                <span className="block mt-4 text-3xl md:text-4xl lg:text-5xl xl:text-6xl transition-all tracking-wider text-black dark:text-white">
                  MZILI Nassim
                </span>
              </h2>

              {/* typing profitions */}
              <h2 className="mt-2 lg:mt-6 text-md sm:text-xl md:text-2xl croissant-One_font text-center lg:text-left">
                <span>
                  <HeroTypeWritter
                    words={["Full-Stack Web Developer..."]}
                    speed={100}
                  />
                </span>
              </h2>

              <p className="mt-6 hidden lg:block text-2xl text-black lg:text-left dark:text-texlight">
                Développeur Web Full-Stack en devenir et étudiant motivé,
                passionné par la création d’applications web simples et
                efficaces. J’apprends activement les technologies front-end et
                back-end pour concevoir des solutions modernes et innovantes. Je
                suis engagé à améliorer mes compétences et à participer à des
                projets numériques importants.
              </p>

              <Link
                to="/contact"
                className="px-8 mt-6 hidden lg:block w-fit py-3 border border-black shadow-inner shadow-white croissant-One_font rounded-xl active:95 group hover:border-primary dark:border-texlight dark:shadow-texlight"
              >
                <span className="text-black group-hover:text-transparent group-hover:from-primary group-hover:to-secondary group-hover:bg-clip-text group-hover:bg-gradient-to-r dark:text-texlight">
                  Contacter
                </span>
              </Link>
            </div>

            {/* Right */}
            <div className="w-full md:border-l dark:border-gray-700 border-gray-300 rounded flex flex-col gap-5 p-5">
              {links?.map((link) => {
                return (
                  <Link
                    key={link.id}
                    to={link.url}
                    target="_blank"
                    className="border dark:border-gray-900 border-gray-300 shadow-sm shadow-gray-100 flex items-center justify-start md:gap-7 gap-2 sm:px-7 px-3 sm:py-3 py-2 rounded"
                  >
                    <div
                      style={{ background: link.color }}
                      className="md:text-4xl text-lg rounded-full p-3"
                    >
                      <link.Icon className="text-white" />
                    </div>
                    <div className="cursor-pointer flex flex-col gap-1">
                      <h1 className="md:text-xl text-sm capitalize dark:text-white text-gray-600 font-semibold">
                        {link.title}
                      </h1>
                      <p className="md:text-xl text-sm text-blue-400">
                        {link.desc}
                      </p>
                    </div>
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AllLinks;
