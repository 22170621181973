import React, { useEffect, useState } from "react";

const HeroTypeWritter = ({ words, speed }) => {
  const [currentWordIndex, setcurrentWordIndex] = useState(0);
  const [currentText, setcurrentText] = useState("");

  const currentWord = words[currentWordIndex];

  useEffect(() => {
    let charIndex = 0;
    const typingSpeed = setInterval(() => {
      if (charIndex <= currentWord.length) {
        setcurrentText(currentWord.slice(0, charIndex));
        charIndex++;
      } else {
        clearInterval(typingSpeed);
        setTimeout(() => {
          setcurrentWordIndex((preIndex) =>
            preIndex === words.length - 1 ? (preIndex = 0) : preIndex + 1
          );
        }, 1000);
      }
    }, speed);

    return () => clearInterval(typingSpeed);
  }, [currentWord, speed, words]);

  return (
    <>
      <span className="tracking-wider text-transparent bg-clip-text bg-gradient-to-r from-primary to-secondary mt-7">
        A {currentText}
      </span>
    </>
  );
};

export default HeroTypeWritter;
