import React from "react";

const Loding = () => {
  return (
    <>
      <div className="absolute top-0 left-0 z-30 flex items-center justify-center w-full h-full overflow-hidden bg-transparent loading-conatiner">
        <div className="w-16 h-16 border-4 border-black rounded-full loading dark:border-texlight"></div>
      </div>
    </>
  );
};

export default Loding;
