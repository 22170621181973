import React from "react";
import ReactDOM from "react-dom/client";

import { LangueProvider } from "./components/LangueContext";
import App from "./components/App";

import "./styles/index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <LangueProvider>
    <App />
  </LangueProvider>
);
